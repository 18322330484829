<template>
  <StaticPage ref="staticPage">
    <div class="form-wrapper" v-if="routeErrorCode == 407 || routeErrorCode == 408 || routeErrorCode == 410">
        <div v-if="routeErrorCode == 410" class="main">
          <div class="title">Oops!</div>
          <div class="error-img">
            <img src="@/assets/images/pug/warning.png" alt />
          </div>
          <p v-html="$t('loginClient.invailidRafLink')"></p>
          <div class="btn-box">
            <a :href="forexTradingAccountLink" class="el-button ok-btn">
              {{ $t('common.button.createLiveAccount') }}
            </a>
          </div>
        </div>
        <div v-else class="main">
          <div class="title">{{ $t('loginClient.emailIsAlreadyRegistered') }}</div>
          <div class="error-img">
            <img src="@/assets/images/pug/warning.png" alt />
          </div>
          <p v-html="$t('loginClient.existingClientPortalAccess')"></p>
          <div class="btn-box">
            <router-link to="/login" class="el-button ok-btn">
              {{ $t('common.button.login') }}
            </router-link>
          </div>
          <p class="hint">{{ $t('loginClient.forgotPasswordTip') }}</p>
          <div class="btn-box">
            <router-link to="/forgetPassword" class="el-button cancel-btn">
              {{ $t('resetPassword.header') }}
            </router-link>
          </div>
        </div>
    </div>
    <div class="form-wrapper" v-else>
      <div class="title">
        {{ $t('responseMsg.' + routeErrorCode) }}
      </div>
      
      <div class="error-img">
        <img src="@/assets/images/pug/error.png" alt />
      </div>
      <div class="main-greg">
        <div style="text-align:center">
          <p>
            <a :href="`https://${url}`"><u>Click here</u></a>
            to go back to the <br />{{ $config.info.fullName }} homepage, or
            <a :href="'mailto:' + email">
              <u>send us an email</u>
            </a>
            <br />
            to let us know you've encountered an <br />error.
          </p>
          <p>Error code : {{ routeErrorCode }}</p>
        </div>
      </div>
    </div>
  </StaticPage>
</template>

<script>
import StaticPage from '@/components/template/staticPage/StaticPagePug';

export default {
  components: { StaticPage },
  data() {
    return {
      url: '',
      email: '',
      mobileNumber: '',
      ctCode: ''
    };
  },
  mounted() {
    const regulator = 'fsa';
    this.url = this.$config.info.getUrl(regulator);
    this.email = this.$config.info.getEmail(regulator);
    this.mobileNumber = this.$route.query.mobile;
    this.ctCode = this.$route.query.countryPhoneCode;
  },
  computed: {
    routeErrorCode() {
      return this.$route.query.code;
    },
    lang() {
      return this.$store.state.common.lang;
    },
    countryCode() {
      return this.$store.state.common.countryCode;
    },
    forexTradingAccountLink() {
      // logic from ACM-7664, ACM-7639
      const LNAGMAP = {
        zh_CN: 'cn',
        fr_FR: 'fr',
        th: 'th',
        de: 'de',
        es: 'es',
        vi: 'vn',
        id: 'id',
        ar: 'ar',
        ko: 'kr',
        ja: 'jp',
        pt: 'pt'
      };
      let preFix = LNAGMAP[this.lang] || '';
      // malay
      if (this.countryCode === '5015') {
        preFix = '';
      }
  
      const domain = this.GLOBAL_REFERRAL_SITE.replace('www.', '');

      return `https://${preFix ?  `${preFix}.`: ''}${domain}/forex-trading-account/`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/loginForm.scss';
@import '@/assets/css/pages/register/error.scss';
</style>
